$black: #000;
$gray: #bdbdbd;
$red: #FF0000;
$white: #fff;
$yellow: #FFF500;

$line-height: 1.8rem;
$reading: 1rem;

$breakpoints: (
  "xs": 400px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xl+": 1400px,
  "xxl": 1600px,
  "xxxl": 1800px
);
