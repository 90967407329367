@import "src/assets/scss/variables";
@import "src/assets/scss/fonts";
@import "src/assets/scss/function";
@import "src/assets/scss/mixins";

.Researches {
  background-color: $black;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffff;
  font-family: PlayFairDisplay, sans-serif;
  background-position: center center;

  .container {
    background: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    left: 0;
    padding-bottom: 94px;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 0;

    @include breakpoint(lg) {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.4290091036414566) 40%,
        rgba(251, 250, 250, 0) 100%
      );
      height: max-content;
      padding-left: 155px;
    }
  }

  h2 {
    font-family: Edition, sans-serif;
    font-size: pxToRem(55px);
    font-weight: 100;
    margin: 0;
    padding: 100px 0 10px 0;
    text-align: center;

    @include breakpoint(lg) {
      font-family: PlayFairDisplay, sans-serif;
      font-size: pxToRem(50px);
      font-weight: 900;
      padding-top: 120px;
      text-align: left;
    }
  }

  .status {
    font-family: Arial, sans-serif;
    font-size: pxToRem(12px);
    font-weight: 600;
    margin: 50px 0;
    padding-left: 44px;

    @include breakpoint(lg) {
      height: 105px;
      padding: unset;
    }

    .status-mobile {
      display: flex;

      @include breakpoint(lg) {
        display: none;
      }

      p {
        margin: 0 5px 0 0;

        @include breakpoint(xs) {
          margin: 0 10px 0 0;
        }

        &:last-child {
          margin: unset;
        }

        &.selected {
          color: $red;
          text-decoration: underline;
        }
      }
    }

    .status-desktop {
      display: none;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;

      @include breakpoint(lg) {
        display: flex;
      }
    }
  }

  .message-action {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 100px;
    padding: 0 20px;

    @include breakpoint(lg) {
      padding: 0;
    }

    p {
      font-family: PlayfairDisplay, sans-serif;
      font-size: pxToRem(19px);
      font-weight: 600;
      line-height: 30px;
      margin: 0;

      @include breakpoint(lg) {
        font-size: pxToRem(30px);
      }
    }

    a {
      padding: 10px;
      background-color: #ca3030;
      width: 200px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: unset;
      text-decoration: inherit;

      button {
        border: none;
        border-bottom: 1px solid $white;
        font-weight: 900;
        background-color: #ca3030;
        color: $white;
        padding: 0;
        cursor: pointer;
        font-family: PlayfairDisplay, sans-serif;
        font-size: pxToRem(18px);
        outline: transparent;
      }
    }
  }

  .description-container {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px;

    @include breakpoint(lg) {
      flex-direction: column;
      padding: unset;
    }

    .container-info {
      display: flex;
      gap: 20px;
      margin-top: 100px;
      flex-direction: column;

      @include breakpoint(lg) {
        border-radius: 12px;
        box-sizing: border-box;
        padding: 30px;
        flex-direction: row;
        max-width: 1140px;
        height: 400px; // Defina uma altura fixa
        overflow: hidden;
        background: #000000b2;
        border: 1px solid #6a6969cc;
        width: 100%;
      }

      @include breakpoint("xxl") {
        flex-direction: row;
        max-width: 1430px;
        width: 100%;
      }

      .research-image {
        width: 100%;
        height: 280px;

        @include breakpoint(lg) {
          width: 410px;
          height: 334px;
          object-fit: cover;
        }
      }
    }

    .title-description {
      @include breakpoint(lg) {
        // height: 330px;
        // width: 500px;
        width: 60%;
        // margin-top: -30px;
      }

      h3 {
        font-family: PlayfairDisplay, sans-serif;
        color: $red;
        font-size: pxToRem(20px);
        font-weight: 600;
        margin-top: 0;

        @include breakpoint(lg) {
          font-size: pxToRem(25px);
        }
      }

      .description-text {
        @include breakpoint(lg) {
          overflow-y: scroll;
          height: 66%;
          padding-bottom: 20px;
        }

        &::-webkit-scrollbar {
          display: none; // Esconde a barra de rolagem
        }
      }

      p {
        font-family: PlayfairDisplay, sans-serif;
        font-size: pxToRem(25px);
        font-weight: 400;
        line-height: 30px;

        &.project-status {
          color: $gray;
          font-family: Arial, sans-serif;
          font-size: pxToRem(14px);
          overflow: unset;
          margin: 25px 0 0 0;

          @include breakpoint(lg) {
            font-size: pxToRem(20px);
          }
        }
      }

      a {
        display: flex;
        text-decoration: none;

        button {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid $white;
          color: $white;
          cursor: pointer;
          font-family: PlayfairDisplay, sans-serif;
          font-size: pxToRem(18px);
          font-weight: 400;
          outline: transparent;
        }

        span {
          align-items: center;
          background-color: $white;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          height: 19px;
          justify-content: center;
          margin-left: 5px;
          padding: 2px;
          width: 19px;

          svg {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }
}
