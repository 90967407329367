@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.EvidenceTableCell {
  padding: 26px 0;
  vertical-align: top;

  img {
    display: block;
    height: 100px;
    margin: 0 auto;
    width: 280px;
  }

  a {
    color: $black;
    text-decoration: none;

    p {
      font-family: PlayfairDisplay, sans-serif;
      font-size: pxToRem(16px);
      font-weight: 400;
      margin: 10px auto;
      text-align: left;

      &.paragraph {
        margin: 10px auto;
        max-width: 280px;
      }
    }
  }

  .number {
    font-family: PlayfairDisplay, sans-serif;
    font-size: pxToRem(70px);
    font-weight: 600;
    margin: 0 auto;
    max-width: 280px;
  }

  a {
    display: block;
    margin: 0 auto;
    margin-left: calc((100% - 280px) / 2);
    width: max-content;

    button {
      background-color: $black;
      border: none;
      color: $white;
      font-family: PlayfairDisplay, sans-serif;
      font-size: pxToRem(12px);
      font-weight: 600;
      outline: transparent;
      padding: 5px;
    }
  }
}
