@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/fonts";

.FormManager {
  .tabs {
    display: flex;
    height: unset;
    margin: 0;
    padding: 0;
    flex-direction: column;

    @include breakpoint(md) {
      margin-left: 42px;
      flex-direction: row;
    }

    @include breakpoint(xl) {
      border-bottom: 1px solid $white;
      height: 70px;
      margin: 0 77px 0 155px;
    }

    p {
      border: 1px solid white;
      font-size: pxToRem(16px);
      font-weight: 600;
      padding: 15px 21px;
      transition: 0.4s;
      cursor: pointer;
      margin: 0;

      @include breakpoint(xl) {
        font-size: pxToRem(18px);
        margin: 10px 0 0 0;
        padding: 15px 50px;
      }

      &.active {
        background-color: $white;
        color: $black;
        transform: scale(1.1);
        transition: ease-in-out;
      }
    }
  }

  .message-action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-right: 80px;

    @include breakpoint(lg) {
      padding-right: 80px;
    }

    h4 {
      font-size: pxToRem(30px);
      font-weight: 600;
      margin: 0;

      @include breakpoint(xl) {
        font-size: pxToRem(32px);
        width: 50%;
      }
    }
  }

  .messageForm {
    padding: 80px 41px 50px 42px;

    @include breakpoint(xl) {
      padding-left: 240px;
      width: 45%;
    }

    h4 {
      font-size: pxToRem(30px);
      font-weight: 600;
      margin: 0;

      @include breakpoint(xl) {
        font-size: pxToRem(32px);
        width: 50%;
      }
    }
  }
}
