@import "src/assets/scss/variables";
@import "src/assets/scss/fonts";
@import "src/assets/scss/function";
@import "src/assets/scss/mixins";

.Donation {
  align-items: center;
  // background: rgb(147, 147, 255);
  // background: linear-gradient(
  //   101deg,
  //   rgba(147, 147, 255, 1) 18%,
  //   rgba(252, 0, 87, 1) 91%
  // );
  display: flex;
  height: 15vh;
  justify-content: space-evenly;
  border-top: 1px solid #ffff;

  a {
    align-items: center;
    color: #ffff;
    display: flex;
    flex-direction: column;
    font-family: PlayfairDisplay;
    font-size: pxToRem(14px);
    font-weight: 900;
    text-decoration: none;
    min-width: 200px;
  }

  svg {
    height: 32px;
  }
}
