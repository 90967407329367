@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.Carousel {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 20px;

  @include breakpoint(lg) {
    flex-wrap: wrap;
    overflow-x: unset;
    // width: 480px;
    padding: 100px 0 0 0;
    max-width: 1600px;
  }

  img {
    box-sizing: border-box;
    cursor: pointer;
    // filter: grayscale(1);
    height: 190px;
    margin-right: 16px;
    width: 277px;

    @include breakpoint(lg) {
      height: 190px;
      margin: 11px 11px 0 0;
      width: 277px;
    }

    &:hover,
    &.active {
      border: 2px solid $red;
      box-shadow: 0px 0px 4px 6px #de7d7d;
      filter: unset;
    }

    &.filtered {
      filter: unset;
    }
  }
}
