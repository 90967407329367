@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";

.Timeline {
  background-color: $black;
  background-size: cover;

  color: #ffff;
  font-family: PlayFairDisplay;
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/images/timeline.png");
    background-size: cover;
    filter: grayscale(100%);
    z-index: -1;
  }

  @include breakpoint(lg) {
    min-height: 100vh;
  }

  h2 {
    font-family: Edition;
    font-size: pxToRem(68px);
    font-weight: 200;
    margin: 0;
    padding: 10px;
    text-align: center;

    @include breakpoint(xl) {
      font-family: PlayFairDisplay;
      font-size: pxToRem(60px);
      font-weight: 900;
      padding-left: 80px;
      padding-top: 60px;
      text-align: left;
    }
  }

  .text-timeline {
    display: flex;
    flex-direction: column;
    max-width: 1211px;

    h3 {
      font-family: PlayFairDisplay;
      font-size: pxToRem(23px);
      font-weight: 500;
      margin: 0;
      padding: 10px;
      text-align: left;

      @include breakpoint(xl) {
        font-family: PlayFairDisplay;
        font-size: pxToRem(30px);
        font-weight: 500;
        padding-left: 80px;
        padding-top: 60px;
        text-align: left;
      }
    }
  }

  .items {
    padding: 50px 0 30px;
    position: relative;

    &::after {
      background-color: #ffff;
      content: "";
      bottom: 0;
      left: 22%;
      margin-top: 70px;
      position: absolute;
      top: 0;
      width: 3px;
    }

    @include breakpoint(xl) {
      border-top: 2px solid #ffff;
      display: flex;
      justify-content: space-between;
      left: 4%;
      margin-top: 220px;
      width: 86%;

      &::after {
        content: none;
      }
    }

    article {
      left: 30%;
      padding: 10px 40px;
      position: relative;
      width: 50%;

      &::after {
        content: "";
        border: 2px solid #ffff;
        border-radius: 50%;
        height: 20px;
        left: -15%;
        position: absolute;
        top: 26px;
        width: 20px;
      }

      @include breakpoint(xl) {
        // height: 400px;
        left: 0;
        padding: 0;
        width: 155px;

        @include breakpoint(xxl) {
          left: 0;
          padding: 0;
          width: 245px;
        }

        &::after {
          height: 25px;
          left: 0;
          top: 0;
          // top: -16%;
          top: -65px;
          width: 25px;
        }
      }

      .container {
        position: relative;

        &::after {
          content: "";
          border-bottom: 3px dotted #ffff;
          left: -27%;
          position: absolute;
          top: 16%;
          width: 44px;
        }

        @include breakpoint(xl) {
          &::after {
            border-left: 3px dotted #ffff;
            height: 35px;
            left: 9%;
            margin-bottom: 20px;
            top: -40px;
            width: 0px;
          }
        }

        @include breakpoint(xxl) {
          &::after {
            left: 6%;
          }
        }

        h3 {
          font-size: pxToRem(37px);
          margin: 0;

          @include breakpoint(xl) {
            font-size: pxToRem(39px);
          }
        }

        p {
          font-size: pxToRem(12px);
          // line-height: 1.4rem;
          overflow-y: hidden;

          @include breakpoint(xl) {
            font-size: pxToRem(20px);
          }

          &.expanded {
            height: max-content;
          }
        }

        button {
          display: none;
          outline: transparent;

          @include breakpoint(xl) {
            background-color: transparent;
            border: none;
            color: #ffff;
            cursor: pointer;
            display: block;
            font-family: PlayFairDisplay;
            padding: 0;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
