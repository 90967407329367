@mixin font-face($font-family, $font-suffix, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url($font-suffix) format("truetype"),
      url($font-suffix) format("opentype");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@include font-face(Edition, "../fonts/EDITION_.TTF", 100, normal);
@include font-face(PlayfairDisplay, "../fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf", 100, italic);
@include font-face(PlayfairDisplay, "../fonts/PlayfairDisplay-VariableFont_wght.ttf", 100, normal);
@include font-face(PlayfairDisplay, "../fonts/PlayfairDisplay-Black.ttf", 900, normal);
@include font-face(PlayfairDisplay, "../fonts/PlayfairDisplay-Bold.ttf", 600, normal);
@include font-face(PlayfairDisplay, "../fonts/PlayfairDisplay-Medium.ttf", 400, normal);
