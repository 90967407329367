@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";

.References {
  background-color: $black;
  box-sizing: border-box;
  color: white;
  padding: 70px 20px;

  @include breakpoint(md) {
    flex-direction: row;
    height: max-content;
    padding: 80px 0 80px 20%;
  }

  @include breakpoint (lg) {
    padding-left: 40%;
  }

  .article-cut {
    left: 30px;
    margin-top: 60px;
    position: relative;

    @include breakpoint (lg) {
      left: unset;
    }

    & > div {
      background-image: url("../../../../assets/images/referencia-recorte.svg");
      background-size: cover;
      background-repeat: no-repeat;
      box-sizing: border-box;
      min-height: 200px;
      opacity: 0.3;
      padding-bottom: 30px;
      position: absolute;
      transition: 0.2s;
      width: 90%;

      @include breakpoint(md) {
        width: 470px;
      }

      &:hover {
        transform: translateY(-10px);
      }

      &:first-child {
        left: -40px;
        top: -40px;
      }

      &:nth-child(2) {
        left: -20px;
        top: -20px;
      }

      &:last-child {
        position: relative;
        opacity: 1;
      }

      p {
        background-color: #6fcebb;
        font-family: PlayfairDisplay, sans-serif;
        font-weight: 600;
        margin: 0;
        padding: 10px;
        width: max-content;
      }

      h3 {
        color: $black;
        font-family: PlayfairDisplay, sans-serif;
        font-size: 1.1rem;
        margin: 20px;
      }

      .authors {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 20px;

        div {
          margin-right: 30px;

          p {
            background-color: transparent;
            color: $black;
            font-family: PlayfairDisplay, sans-serif;
            font-weight: lighter;
            margin: 0;
            padding: unset;
            width: unset;

            &:first-child {
              font-size: 0.9rem;
            }

            &:last-child {
              color: #9d9d9d;
              font-size: 0.8rem;
              white-space: pre-wrap;
            }
          }
        }

        &.one-author {
          justify-content: flex-start;
        }
      }
    }
  }

  article {
    display: flex;
    position: relative;
    top: -15px;

    @include breakpoint (md) {
      width: 480px;
    }

    @include breakpoint(lg) {
      width: 520px;
    }

    div {
      display: flex;
      flex-direction: column;

      &.selectors {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 90px;
        justify-content: space-between;
        margin-top: 50px;

        span {
          border: 1px solid $white;
          cursor: pointer;
          padding: 5px;

          &.inactive {
            opacity: 0.5;
          }

          &:last-child {
            svg {
              transform: rotate(180deg);
            }
          }

          svg {
            height: 20px;
            width: 20px;
          }
        }
      }

      &:nth-child(2) {
        align-items: center;
        margin: 0 20px;

        @include breakpoint(lg) {
          margin: 0 20px 0 60px;
        }

        span {
          background-color: $white;
          display: block;

          &:first-child {
            height: 90px;
            width: 1px;
          }

          &:last-child {
            height: 10px;
            width: 10px;
          }
        }
      }

      &:last-child {
        margin-top: 90px;

        h3,
        h4 {
          margin: 0;
        }

        p {
          font-family: PlayfairDisplay, sans-serif;
          font-size: pxToRem(16px);
          font-weight: lighter;
          line-height: 1.5rem;
        }

        a {
          color: $white;
          line-height: 1.5rem;
          margin: 10px 0;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          &:nth-child(3) {
            align-self: flex-end;
            margin: 0 0 20px;

            button {
              background-color: $black;
              border: 1px solid $white;
              color: $white;
              cursor: pointer;
              padding: 5px;
              text-align: left;
              width: 80px;
            }
          }
        }
      }
    }
  }
}
