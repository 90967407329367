@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/fonts";

.Research {
  display: flex;
  background-color: $black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: flex-end;
  min-height: max-content;
  padding: 90px 20px 20px;

  @include breakpoint(lg) {
    align-items: center;
    background-position: unset;
    min-height: calc(100vh - 129px);
    padding: 45px 200px 0;
  }

  div {
    background-image: url("../../../../assets/images/recorte.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 425px;
    padding: 15px 15px 30px;
    width: 100%;

    @include breakpoint(lg) {
      padding: 25px 25px 42px 25px;
      width: 400px;
    }

    p {
      color: $black;
      font-family: PlayfairDisplay, sans-serif;
      font-size: pxToRem(16px);
      font-weight: 400;
      height: 100%;
      line-height: 2rem;
      margin: 0;
      overflow-y: scroll;

      @include breakpoint(lg) {
        width: 400px;
      }
    }
  }
}
