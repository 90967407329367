@import "src/assets/scss/variables";
@import "src/assets/scss/fonts";
@import "src/assets/scss/function";
@import "src/assets/scss/mixins";

.Team {
  background: $black;
  color: $white;
  position: relative;

  @include breakpoint(lg) {
    min-height: 100vh;
    padding: 0 50px;
  }

  h2 {
    font-family: PlayfairDisplay;
    font-size: pxToRem(74px);
    font-weight: 200;
    letter-spacing: 1px;
    margin: 0;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(xl) {
      font-size: pxToRem(119px);
      padding-top: 200px;
    }
  }

  .title-team {
    margin-top: 80px;

    @include breakpoint(xl) {
      margin: 0;
    }
  }

  .container-whoweare {
    padding: 0 20px;

    h2 {
      padding-top: 100px;
      text-align: left;
    }

    p {
      font-size: pxToRem(30px);
      padding: 0;
      text-align: left;
    }
  }

  .container-about {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    h2 {
      font-family: PlayfairDisplay;
      font-size: pxToRem(65px);
      padding-top: 100px;
      width: 100%;
      text-align: center;
    }

    p {
      font-size: pxToRem(25px);
      text-align: left;
      width: 100%;

      @include breakpoint(lg) {
        padding-left: 51px;
      }
    }

    ul {
      padding: 0;
      margin: 2px 0;
      text-align: left;

      li {
        font-size: pxToRem(25px);
        text-align: left;
        width: 100%;
      }
    }

    div {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 500px;
    }

    .list-values {
      max-width: 300px;

      ul {
        text-align: left;
        width: 100%;
        padding-left: 40px;
      }
    }

    @include breakpoint(lg) {
      align-items: center;
      flex-direction: row;
      justify-content: center;
      justify-content: space-evenly;
      margin-top: 90px;

      div {
        display: flex;
        height: 482px;
        justify-content: flex-start;
        align-items: center;
        width: 30%;
      }
    }
  }

  .section-people {
    width: 100%;

    @include breakpoint(xl) {
      display: flex;
      justify-content: center;
    }
  }

  .people {
    height: 234px;
    overflow-y: scroll;
    padding: 50px 0px 60px 30px;
    scroll-snap-type: y mandatory;
    border-top: 1px solid $white;

    &.active {
      height: 125px;
    }

    .cell {
      align-items: flex-end;
      display: flex;
      margin-bottom: 17px;
      transition: 0.4s;
      width: fit-content;

      &.showText {
        transition: 0.5s;
        &:hover {
          h3,
          p {
            display: flex;
          }
          img {
            transition: 0.5s;
            width: 170px;
          }
        }
      }

      &.active {
        img {
          transition: 0.5s;
          width: 180px;
          height: 180px;
          object-fit: cover;
        }

        h3,
        p {
          display: flex;
        }
      }

      h3,
      p {
        display: none;
        font-family: PlayFairDisplay;
        font-size: pxToRem(13px);

        @include breakpoint(xl) {
          font-size: pxToRem(20px);
        }
      }

      img {
        width: 80px;

        @include breakpoint(xl) {
          width: 100px;
        }
      }

      h3 {
        color: #808080;
        display: none;
        margin: 0;
        padding-left: 10px;
      }

      p {
        margin: 0 4px;
        padding-right: 22px;

        @include breakpoint(xl) {
          padding-left: 10px;
        }
      }
    }

    @include breakpoint(xl) {
      height: unset;
      overflow: hidden;
      display: grid;
      grid-template-columns: repeat(2, 525px); /* 2 colunas */
      gap: 16px 5%; /* Espaço entre os itens */
      justify-content: space-between;
      max-width: 1400px;
    }
  }
}
