@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.EvidenceTableArea {
  background-color: $white;

  & > div:first-child {
    padding: 42px 30px 0;

    @include breakpoint(lg) {
      padding-left: 100px;
    }

    h2 {
      font-family: PlayfairDisplay, sans-serif;
      font-weight: 900;
      font-size: pxToRem(30px);
      text-transform: uppercase;
      letter-spacing: 5px;
      text-align: center;

      @include breakpoint(lg) {
        font-size: pxToRem(50px);
      }
    }

    p {
      font-family: PlayfairDisplay, sans-serif;
      font-weight: 600;
      font-size: pxToRem(20px);

      @include breakpoint(lg) {
        max-width: 445px;
        font-size: pxToRem(30px);
      }

      &:first-child {
        font-weight: 600;
      }

      &:last-of-type {
        color: #6a6969;
        font-size: pxToRem(20px);
        max-width: 207px;
      }
    }
  }

  .table-scroll-wrapper {
    padding: unset;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .tabs {
      align-items: flex-end;
      background-color: $white;
      display: flex;
      left: 0;
      padding: 50px 0 0;
      position: sticky;
      top: 0;
      z-index: 2;

      @include breakpoint(lg) {
        padding-left: 100px;
      }

      p {
        border: 1px solid $black;
        color: $black;
        cursor: pointer;
        font-family: PlayfairDisplay, sans-serif;
        font-size: pxToRem(20px);
        font-weight: 600;
        height: max-content;
        margin: 0;
        max-width: unset;
        padding: 10px;
        text-align: center;
        width: 50%;

        @include breakpoint(lg) {
          width: 270px;
        }

        &:first-child {
          border-right: none;
        }

        &:last-child {
          border-left: none;
          color: $black;
        }

        &.active {
          padding: 20px;

          &:first-child {
            border-right: 1px solid $black;
          }

          &:last-child {
            border-left: 1px solid $black;
          }
        }
      }
    }

    table {
      border-collapse: collapse;
      display: -moz-groupbox;
      height: 100%;
      overflow-x: scroll;
      width: 100%;

      &::-webkit-scrollbar-track {
        background-color: white;
        border: 2px solid rgba(120, 120, 120, 0.3);
      }

      &::-webkit-scrollbar {
        display: none;
        width: 0;
      }

      thead {
        tr {
          height: 40px;
          position: relative;

          th {
            background-color: $black;
            color: $white;
            min-width: 290px;
            overflow: auto;
            position: sticky;
            top: 115px;
            padding: 10px 16px;
            z-index: 2;

            @include breakpoint(lg) {
              min-width: unset;
            }

            &:first-child {
              min-width: unset;
            }
          }
        }
      }

      tbody {
        height: 100%;

        tr {
          width: 100%;

          &:nth-child(2) {
            td,
            .first-cell-desktop {
              @include breakpoint(lg) {
                border-top: 1px solid $black;
              }
            }
          }

          &:nth-child(4),
          &:nth-child(8) {
            .first-cell-desktop {
              p {
                top: 50px;
              }
            }
          }

          .first-cell-desktop {
            p {
              position: relative;
            }
          }

          &.subheader-mobile {
            box-sizing: border-box;
            padding-top: 30px;

            @include breakpoint(lg) {
              display: none;
            }

            td {
              font-family: PlayfairDisplay, sans-serif;
              font-size: pxToRem(22px);
              font-weight: 600;
              padding: 26px 26px 0;
            }
          }

          td {
            @include breakpoint(lg) {
              border-bottom: 1px solid $black;
              border-right: 1px solid $black;

              &:last-child {
                border-right: none;
              }
            }
          }
        }
      }

      .first-cell-desktop {
        display: none;
        max-width: 100px;
        padding: unset;
        width: 100px;

        @include breakpoint(lg) {
          background-color: $white;
          display: table-cell;
          width: 100px;

          p {
            font-family: PlayfairDisplay, sans-serif;
            font-size: pxToRem(22px);
            font-weight: 600;
            margin: 0;
            max-width: 200px;
            transform: rotate(-90deg);
            transform-origin: 50px;
            width: max-content;
          }
        }
      }
    }
  }
}
