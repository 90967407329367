@import "../assets/scss/variables";
@import "../assets/scss/function";

html {
  background: white;
}

body {
  margin: 0;

  .LanguageSelector {
    border-bottom: 1px solid $white;
    // bottom: 30px;
    top: 30px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    // left: 30px;
    right: 30px;
    mix-blend-mode: difference;
    position: fixed;
    z-index: 4;

    p {
      color: $white;
      font-family: PlayfairDisplay;
      font-size: pxToRem(15px);
      font-weight: 100;
      margin: 0;
      padding: 0 5px;

      &.active {
        font-weight: bold;
      }

      &:first-child {
        border-right: 1px solid $white;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
}
