@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/fonts";

.SupportPage {
  background-color: $black;
  color: #ffff;
  font-family: PlayFairDisplay;
  padding-bottom: 90px;
  position: relative;
  width: 100%;

  img {
    display: none;

    @include breakpoint(xl) {
      display: block;
      left: 28%;
      position: absolute;
      right: 0;
      top: -40px;
    }
  }

  div {
    padding:10px 20px 60px 20px;

    @include breakpoint(xl) {
      padding-bottom: 80px;
      padding-left: 155px;
    }
  }

  .top-navigation {
    font-family: Edition;
    font-size: pxToRem(68px);
    font-weight: 200;
    margin: 0;
    padding: 10px;
    text-align: center;

    @include breakpoint(xl) {
      font-family: PlayFairDisplay;
      font-size: pxToRem(38px);
      font-weight: 900;
      padding-top: 40px;
      text-align: left;
    }
  }

  article {
    padding: 0 41px 50px 42px;
    width: 75%;

    @include breakpoint(xl) {
      padding-bottom: 100px;
      padding-left: 155px;
      position: relative;
      width: 40%;
      z-index: 2;
    }

    h3 {
      padding: 0;
      text-align: left;
      width: 100%;

      @include breakpoint(xl) {
        font-size: pxToRem(38px);
        font-weight: 900;
        width: 50%;
      }
    }

    p {
      color: $gray;
      font-size: pxToRem(14px);
      font-weight: 200;
      padding-bottom: 20px;
      text-align: left;

      @include breakpoint(xl) {
        color: white;
        font-size: pxToRem(25px);
        font-weight: 500;
      }
    }

    h2 {
      font-size: pxToRem(33px);
      font-weight: 600;
      text-align: left;

      @include breakpoint(xl) {
        font-size: pxToRem(42px);
        width: 85%;
      }
    }
  }

  hr{
    border-top: 1px solid $gray;
    margin: 0 0 0 42px;
    width: 80%;

    @include breakpoint(xl) {
      margin:0 77px 0px 155px;
      width: auto;
    }
  }
}
