@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/fonts";

.research-nav {
  background-color: $black;
  height: 80px;
  position: relative;
  z-index: 2;

  @include breakpoint(lg) {
    padding: 0 100px 0 200px;
  }

  div {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
    padding: 0 60px;

    @include breakpoint(lg) {
      overflow-x: hidden;
    }

    a {
      text-decoration: none;

      p {
        box-sizing: border-box;
        color: $white;
        cursor: pointer;
        font-family: PlayfairDisplay, sans-serif;
        font-size: pxToRem(18px);
        font-weight: 900;
        margin: 0 0 0 20px;
        max-height: 80px;
        min-width: max-content;
        padding: 30px 0;

        &.active {
          border-bottom: 2px solid $white;
        }

        &:hover {
          opacity: 0.7;
          border-bottom: 2px solid $white;
        }
      }
    }
  }
}

.path {
  align-items: center;
  display: flex;
  padding: 20px 0 20px 10px;
  position: absolute;
  top: 80px;

  @include breakpoint(lg) {
    padding: 20px 100px 0;
  }

  a {
    height: 25px;
    svg {
      cursor: pointer;
      height: 25px;
      width: 25px;
    }
  }

  article {
    align-items: center;
    display: flex;
    margin-left: 5px;

    @include breakpoint(xs) {
      margin-left: 15px;
    }

    @include breakpoint(lg) {
      margin-left: 75px;
    }

    p {
      color: $white;
      font-family: PlayfairDisplay, sans-serif;
      font-size: pxToRem(14px);
      font-weight: 400;
      margin: 0;

      &:nth-child(2) {
        margin: 0 5px;

        @include breakpoint(xs) {
          margin: 0 10px;
        }
      }

      &:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: max-content;
      }
    }
  }
}
