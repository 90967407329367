.Loading {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;

  & > .lds-ripple {
    display: inline-block;
    height: 80px;
    position: relative;
    width: 80px;

    & > div {
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      border: 4px solid #fff;
      border-radius: 50%;
      opacity: 1;
      position: absolute;

      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }

  @keyframes lds-ripple {
    0% {
      height: 0;
      left: 36px;
      opacity: 1;
      top: 36px;
      width: 0;
    }

    100% {
      height: 72px;
      left: 0px;
      opacity: 0;
      top: 0px;
      width: 72px;
    }
  }
}
