@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";

.Action {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  overflow-x: hidden;

  @include breakpoint(lg) {
    background-position: top;
    height: calc(100vh - 80px);
  }

  img {
    display: none;

    @include breakpoint(lg) {
      display: block;
      height: 75%;
      left: -15px;
      position: absolute;
      top: 70px;
      width: 100%;
    }
  }

  .container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 70px 20px;

    @include breakpoint(lg) {
      flex-direction: row;
      padding: 123px 200px 10px;
    }

    div {
      display: flex;
      height: 100%;
      justify-content: flex-start;
      margin-top: 50px;

      @include breakpoint(lg) {
        margin: 0;
        width: 50%;
      }

      &:first-child {
        align-items: flex-start;
      }

      &:last-child {
        align-items: flex-end;
      }

      p {
        color: $white;
        font-family: PlayfairDisplay, sans-serif;
        font-size: pxToRem(20px);
        font-weight: 400;
        line-height: 2rem;
        margin: 0;
        width: 451px;

        @include breakpoint(lg) {
          font-size: pxToRem(17px);
        }
      }
    }
  }
}
