@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/fonts";

.Problem {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .container {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    height: 100vh;
    padding: 70px 20px 20px;

    @include breakpoint(lg) {
      padding: 45px 200px 0;
    }

    p {
      color: $white;
      font-family: PlayfairDisplay, sans-serif;
      font-size: pxToRem(20px);
      font-weight: 400;
      line-height: 2rem;
      margin: 0;

      @include breakpoint(lg) {
        margin: 100px 0;
        width: 600px;
      }

      span {
        color: $yellow;
        font-family: PlayfairDisplay, sans-serif;
        font-size: pxToRem(32px);
        font-weight: 600;
      }
    }
  }
}
