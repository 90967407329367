@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/fonts";

.FormFields {
  display: flex;
  flex-direction: column;
  padding: 0 41px 50px 42px;

  @include breakpoint(xl) {
    padding-left: 240px;
    width: 40%;
  }

  label {
    display: flex;
    flex-direction: column;
    font-size: pxToRem(18px);
    padding-top: 40px;

    @include breakpoint(xl) {
      font-size: pxToRem(22px);
    }

    input {
      background-color: transparent;
      border: none;
      border-bottom: 2px solid $white;
      color: $white;
      height: 40px;
      padding: 5px 15px;
    }
  }

  .Checkbox {
    align-items: flex-start;
    font-family: PlayFairDisplay;
    font-size: pxToRem(18px);

    @include breakpoint(xl) {
      font-size: pxToRem(22px);
    }

    .checkmark{
      height: 5px;
      
    &:after{
      height: 22px;
      left: 10px;
    }
  }
  }

  .warning {
    color: $gray;
    padding-top: 50px;
  }
  
  span {
    color: $gray;
    padding: 10px 5px;
  }
}

.button-form {
  background: transparent;
  border: 2px solid $white;
  color: $white;
  cursor: pointer;
  font-family: PlayFairDisplay;
  font-size: pxToRem(18px);
  margin: 20px 0 100px 42px;
  padding: 10px 15px;
  width: 50%;

  @include breakpoint(xl) {
    font-size: pxToRem(20px);
    margin: 20px 0 100px 240px;
    width: 15%;
  }

  &:hover {
    background: $white;
    color: $black;
  }
}
.feedback {
  color: #eca8a8;
  font-weight: 600;
  padding: 40px 0 0 30px; 
}

.paypal {
  padding:0 0 100px 42px;

  @include breakpoint(xl) {
    padding: 0 0 100px 240px;
  }

  input[type="image"] {
    width: 48%;

    @include breakpoint(md) {
      height: 72px;
      width: 248px;
    }
  }
}
