@import "src/assets/scss/variables";
@import "src/assets/scss/fonts";
@import "src/assets/scss/function";
@import "src/assets/scss/mixins";

.SideMenu {
  input {
    display: none;

    &:checked ~ label .burger {
      background: #ffff;
      transform: rotate(45deg);
    }
    &:checked ~ label .burger:before {
      background: #ffff;
      top: 0;
      transform: rotate(90deg);
    }
    &:checked ~ label .burger:after {
      background: #ffff;
      bottom: 0;
      transform: rotate(90deg);
    }
  }

  .menu {
    cursor: pointer;
    height: 60px;
    mix-blend-mode: exclusion;
    position: fixed;
    transition: 0.5s ease-in-out;
    width: 60px;
    z-index: 6;

    .burger {
      background: $white;
      display: block;
      height: 2px;
      left: 30px;
      position: relative;
      top: 35px;
      transition: 0.5s ease-in-out;
      width: 30px;

      &::after,
      &::before {
        background: $white;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        transition: 0.5s ease-in-out;
        width: 100%;
      }

      &::before {
        top: -10px;
      }

      &::after {
        bottom: -10px;
      }
    }
    &.active {
      transform: translateX(calc(100vw - 80px));

      @include breakpoint(lg) {
        transform: translateX(370px);
        transition: 0.5s;
      }
    }
  }

  .menu-items {
    background-color: $black;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    position: fixed;
    transition: 0.5s;
    width: 0px;
    z-index: 5;

    .menu-icons {
      display: flex;
      flex-direction: row;

      .items {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 85vh;
        width: 75%;

        svg {
          rect {
            fill: transparent;
          }

          path {
            fill: #ffff;
          }
        }

        .Trust {
          rect {
            fill: revert-layer;
          }
        }

        a {
          color: #ffff;
          font-family: PlayfairDisplay;
          font-size: pxToRem(21px);
          font-weight: 900;
          margin-left: 40px;
          padding-top: 10px;
          display: flex;
          align-items: center;
          gap: 20px;
          width: 250px;
          text-decoration: none;

          @include breakpoint(lg) {
            margin-left: 50px;
          }

          span {
            color: yellow;
            font-size: pxToRem(17px);
            font-weight: 400;
            padding-left: 10px;
          }
        }
      }
    }

    &.active {
      width: 100%;

      @include breakpoint(lg) {
        width: 449px;
      }
    }
  }
}
