@import 'src/assets/scss/variables';
@import 'src/assets/scss/fonts';
@import 'src/assets/scss/function';
@import 'src/assets/scss/mixins';

.Home {
  background-color: #ffffff;

  header {
    img {
      height: 360px;
      width: 100%;

      @include breakpoint(xl) {
        min-height: 100vh;
      }
    }

    h1 {
      color: $red;
      font-family: Edition;
      font-size: pxToRem(165px);
      font-weight: 100;
      left: 0;
      margin: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 12%;

      @include breakpoint(xl) {
        font-size: pxToRem(375px);
        top: 30%;
      }
    }

    h1::after {
      border-bottom: 6px solid $red;
      content: '';
      left: 0;
      margin-left: 14%;
      max-width: 70%;
      position: absolute;
      right: 0;
      top: 50%;

      @include breakpoint(xl) {
        border-bottom: 10px solid $red;
        margin-left: 30%;
        max-width: 40%;
      }
    }
  }

  .heading-container {
    background-color: $white;
    padding: 30px;

    @include breakpoint(lg) {
      padding: 30px 100px;
    }

    @include breakpoint(xl) {
      padding: 106px 135px 106px 100px;
    }

    h3 {
      font-family: PlayfairDisplay;
      font-size: pxToRem(34px);
      font-weight: 900;
      margin: 0;
      opacity: 1;

      @include breakpoint(xl) {
        font-size: pxToRem(84px);
      }
    }

    h5 {
      color: #808080;
      font-family: PlayfairDisplay;
      font-size: pxToRem(24px);
      font-weight: 600;
      margin: 0;
      opacity: 1;

      @include breakpoint(xl) {
        font-size: pxToRem(32px);
        text-align: right;
      }
    }
  }

  .last-section {
    align-items: center;
    background-image: url('../../../../assets/images/Home-people.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 370px;
    justify-content: center;
    padding: 0 31px;

    @include breakpoint(lg) {
      height: 620px;
      padding: 0 160px;
    }

    p {
      color: $white;
      font-family: PlayfairDisplay, sans-serif;
      font-size: pxToRem(23px);
      font-weight: 400;
      margin: 0;
      text-align: center;

      @include breakpoint(md) {
        width: 600px;
      }

      @include breakpoint(lg) {
        font-size: pxToRem(44px);
        width: 882px;
      }
    }
  }

  .somas-container {
    width: 100%;
    // max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 30px;

    @include breakpoint(lg) {
      padding: 30px 100px;
    }

    @include breakpoint(xl) {
      padding: 106px 135px 106px 100px;
    }

    .somas-section {
      margin-bottom: 3rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      // &:nth-child(odd) {
      //   flex-direction: column; /* Textos à esquerda */
      // }

      &:nth-child(even) {
        align-items: flex-end;

        h2,
        p {
          text-align: end;
        }
      }

      h2 {
        font-family: PlayfairDisplay;
        font-weight: bold;
        font-size: pxToRem(30px);
        margin-bottom: 1rem;

        @include breakpoint(xl) {
          font-size: pxToRem(50px);
        }
      }

      h3 {
        font-family: PlayfairDisplay;
        color: #a8a8a8;
        margin: 0;
        opacity: 1;
        font-weight: 500;
        font-size: pxToRem(18px);
      }

      p {
        font-family: PlayfairDisplay;
        font-size: pxToRem(15px);
        font-weight: 500;
        color: #666666;

        @include breakpoint(xl) {
          line-height: 37px;
          font-size: pxToRem(24px);
        }
      }

      & > * {
        flex: 1;
      }
    }
  }
}
